import { Cart, LineItem } from '@commerce/types/cart';

declare global {
  interface Window {
    dataLayer?: unknown[];
  }
}

type UAProduct = {
  productId?: string;
  quantity?: number;
  productName?: string;
  brand?: string;
  sku?: string;
};

const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER as string;

export const pushToGTMDataLayer = (data: any): void => {
  if (GTM_TRACKING_ID) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }
};

export const pushAddToCartToGTMDataLayer = (product: UAProduct, cart: Cart | null | undefined): void => {
  if (product && cart) {
    const { productId, quantity = 1, productName, sku } = product;
    const { lineItems, currency } = cart;
    const lineItemProduct = lineItems.find((item: LineItem) => item.productId === productId);
    if (lineItemProduct) {
      pushToGTMDataLayer({
        addToCartProductIDs: [sku || ''],
        addToCartProductNames: [productName || ''],
        addToCartCurrency: currency.code,
        addToCartPrice: lineItemProduct.variant.price,
        addToCartQuantity: quantity,
      });
    }
  }
};

export const pushUAEcommerceEvent = (product: UAProduct, cart: Cart | null | undefined): void => {
  if (GTM_TRACKING_ID && product && cart) {
    const { productId, quantity = 1, productName, brand, sku } = product;
    const { lineItems, currency } = cart;
    const lineItemProduct = lineItems.find((item: LineItem) => item.productId === productId);

    if (lineItemProduct) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: currency.code,
          add: {
            products: [
              {
                name: productName,
                id: sku,
                price: lineItemProduct.variant.price,
                brand,
                quantity,
              },
            ],
          },
        },
      });
    }
  }
};
