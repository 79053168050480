import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { trackFooterEvent } from '@lib/gtag';
import { renderImage } from '@lib/image';

import style from './DownloadApp.module.scss';

interface Props {
  iosLink?: string;
  androidLink?: string;
  className?: string;
  useTracking?: boolean;
}

const DownloadApp: FC<Props> = ({ iosLink, androidLink, className, useTracking = true }) => {
  const { t } = useTranslation('common');
  const defaultIosLink = t('common:appStore.defaultIosLink');
  const defaultAndroidLink = t('common:appStore.defaultAndroidLink');

  return (
    <div className={cn(style.root, className)}>
      <a
        href={iosLink || defaultIosLink}
        target="_blank"
        rel="noreferrer"
        title={`Download on the App Store ${t('common:screenReader.openInNewTab')}`}
        onClick={() => {
          if (useTracking) {
            trackFooterEvent('App Store');
          }
        }}
      >
        {renderImage(
          { url: '/svgs/Download_on_the_App_Store.svg', alt: t('common:downloadAppStore') },
          { loading: 'lazy' }
        )}
      </a>

      <a
        href={androidLink || defaultAndroidLink}
        target="_blank"
        rel="noreferrer"
        title={`Get it on Google Play ${t('common:screenReader.openInNewTab')}`}
        onClick={() => {
          if (useTracking) {
            trackFooterEvent('Google Play Store');
          }
        }}
      >
        {renderImage(
          { url: '/svgs/Download_on_the_Play_Store.svg', alt: t('common:downloadPlayStore') },
          { loading: 'lazy' }
        )}
      </a>
    </div>
  );
};

export default DownloadApp;
